import React, { Component } from 'react';
import { Input, Button } from 'reactstrap';
import qs from 'qs';
import request from '../services/request';
import Modal from 'react-modal';
import ImageCrop from './lib/ImageCrop';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        height: '500px'
    }
};

class Image extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: props.schema || {},
            showModal: false,
            croppedImageUrl: props.value || null,
            editor: null,
            scaleValue: 1,
            width: props.schema && Number(props.schema.imageWidth) || 250,
            height: props.schema && Number(props.schema.imageHeight) || 260
        }
    }
    async uploadFile(base64file) {
        let file = await this.DataURLtoFile(base64file)
        const formData = new FormData();
        formData.append('images', file);

        let rs = await request.upload('/api/file/upload-image', formData);
        if (this.props.onChange) {
            await this.props.onChange(rs.data);
        }
    }
    setEditorRef = editor => this.setState({ editor });
    onCrop = () => {
        const { editor } = this.state;
        if (editor !== null) {
            const url = editor.getImageScaledToCanvas().toDataURL();
            this.setState({ croppedImageUrl: url, showModal: false });
            this.uploadFile(url)
        }
    };
    onScaleChange = (scaleChangeEvent) => {
        const scaleValue = parseFloat(scaleChangeEvent.target.value);
        this.setState({ scaleValue });
    };
    DataURLtoFile = async (dataurl, filename) => {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };
    profilePicChange = (e) => {
        this.setState({ openCropper: true, selectedImage: e.target.files[0], fileUploadErrors: [], showModal: true });
    };
    handleCloseModal(e) {
        this.setState({ showModal: false, selectedImage: null, croppedImageUrl: null })
    }

    render() {
        return (<div>
            <div>
                <input type="file" accept="image/*" onChange={this.profilePicChange} style={{ display: 'none' }} ref={fileInput => this.fileInput = fileInput} />
            </div>
            {(this.state.selectedImage && this.state.showModal) && (
                <Modal
                    isOpen={this.state.showModal}
                    style={customStyles}>
                    <h2 ref={subtitle => this.subtitle = subtitle}>Crop Image</h2>
                    <div style={{ float: 'right', marginTop: '-6%' }}>
                        <Button className="btn btn-danger btn-sm" onClick={e => { this.handleCloseModal(e) }}>Close</Button>
                    </div>
                    <hr />
                    <ImageCrop
                        imageSrc={this.state.selectedImage}
                        setEditorRef={this.setEditorRef}
                        onCrop={this.onCrop}
                        scaleValue={this.state.scaleValue}
                        onScaleChange={this.onScaleChange}
                        width={this.state.width}
                        height={this.state.height}
                    />
                </Modal>
            )}
            {!this.state.croppedImageUrl && (
                <i className="fa fa-upload fa-3x" style={{ position: 'absolute', top: '40%', left: '15%' }} onClick={() => this.fileInput.click()}></i>
            )}
            <img className='file-picker-thumbnail' style={{ marginTop: '10px' }} height={this.state.height} width={this.state.width}
                src={this.state.croppedImageUrl} onClick={() => this.fileInput.click()} />
        </div>
        )
    }
}

export default Image;