/**
 * @author TuPT
 * @date Feb 19, 2020
 */
import React from 'react';
import helper from '../services/helper';
import MultiSelect from "@khanacademy/react-multi-select";

export default class SelectMutilple extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || [],
            data: props.ARRAY || [],
            pageId: props.schema.pageId,
            schema: props.schema,
            isCallData: props.isCallData || true,
        };
        if (this.state.data.length === 0) this.init(props.schema.pageId, props)
        else this.renameKeyOfArray(this.state.data)
    }
    pageInfo = null;
    schema = null;
    componentWillReceiveProps(next) {
        if (this.state.isCallData) {
            if (this.props.value !== next.value) {
                this.setState({ value: next.value });
                this.fetchData(this.pageInfo, this.schema, next.value);
            }
        } else {
            this.setState({ value: next.value })
        }
    }
    async init(pageId, props) {
        this.pageInfo = await helper.getPage(pageId);
        this.schema = props.schema;
        this.fetchData(this.pageInfo, this.schema, props.value);
    }
    async fetchData(pageInfo, schema, value) {
        if (!pageInfo || !schema) {
            return;
        }
        let filter = {};
        filter.id = value || undefined;
        let key = this.props.schema.modelSelectField.split(',')
        let rs = await helper.callPageApi(pageInfo, schema.api, { queryInput: JSON.stringify(filter), select: key || '' });
        rs.data.map(item => {
            this.renameKey(item, key[0], 'value')
            this.renameKey(item, key[1], 'label')
        })
        // debugger
        await this.setState({ data: rs.data ? rs.data : [] });
    }
    async saveData(val) {
        if (this.props.onChange) {
            this.props.onChange(val)
        }
    }
    async renameKeyOfArray(data) {
        let dtRename = data.map(item => {
            this.renameKey(item, 'key', 'label')
        })
        await this.setState({ data: dtRename })
    }
    renameKey(obj, old_key, new_key) {
        if (old_key !== new_key) {
            Object.defineProperty(obj, new_key, // modify old key 
                // fetch description from object 
                Object.getOwnPropertyDescriptor(obj, old_key));
            delete obj[old_key];                // delete old key 
        }
    }
    render() {
        return (
            <MultiSelect
                options={this.state.data}
                selected={this.state.value}
                onSelectedChanged={value => {
                    this.setState({ value })
                    if (this.props.onChange) {
                        this.props.onChange(value)
                    }
                }}
            />
        )
    }
}