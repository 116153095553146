import React, { Component } from 'react';
import {
    Input,
    InputGroupAddon,
    InputGroup,
    Button
} from 'reactstrap';
import { GoogleMap, Marker, withScriptjs, withGoogleMap, } from "react-google-maps"
const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");
const MapWithAMarker = withScriptjs(withGoogleMap(props =>
    <GoogleMap
        defaultZoom={16}
        defaultCenter={{ lat: props.lat, lng: props.lng }}
    >
        <Marker
            position={{ lat: props.lat, lng: props.lng }}
            draggable={true}
            onDragEnd={evt => {
                if (props.onChange) {
                    let rs = `${evt.latLng.lat()}/${evt.latLng.lng()}`
                    props.onChange(rs);
                }
            }}
        />
        <SearchBox
            ref={props.onSearchBoxMounted}
            bounds={props.bounds}
            controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
        // onPlacesChanged={props.onPlacesChanged}
        >
            <input
                type="text"
                placeholder="Tìm kiếm địa chỉ"
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `500px`,
                    height: `32px`,
                    marginTop: `8px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                }}
            />
        </SearchBox>
    </GoogleMap>
));
class Location extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        }
    }
    render() {
        let rs = [21.0575491, 105.7909262];
        if (this.props.value) {
            rs = this.props.value.split('/');
            if (!isNaN(Number(rs[0])) && !isNaN(Number(rs[1]))) {
                rs[0] = Number(rs[0]);
                rs[1] = Number(rs[1]);
            }
        }
        return (<div>
            <InputGroup>
                <Input type="text" id="name" placeholder="Nhập địa chỉ gps. Ví dụ: 21.0575491/105.7909262" required value={this.props.value}
                    onChange={evt => {
                        if (this.props.onChange) {
                            this.props.onChange(evt.target.value);
                        }
                    }} />
                <InputGroupAddon addonType="append">
                    <Button type="button" color="default">Nhập vị trí</Button>
                </InputGroupAddon>
            </InputGroup>

            <div style={{ marginTop: '15px' }}>
                <MapWithAMarker
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBoV6apMMKQdcDyFhg3L4XZAHCB3eCATg4&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    lat={rs[0]}
                    lng={rs[1]}
                    onChange={val => {
                        if (this.props.onChange) {
                            this.props.onChange(val);
                        }
                    }}
                />
            </div>
        </div>)
    }
}

export default Location;
