import React from 'react';
import Loadable from 'react-loadable'

function Loading() {
    return <div>Loading...</div>;
}

const Dashboard = Loadable({
    loader: () => import('./views/Dashboard'),
    loading: Loading
});
const Profile = Loadable({
    loader: () => import('./views/User/Profile'),
    loading: Loading
});
const PageEditor = Loadable({
    loader: () => import('./views/PageManager/PageEditor'),
    loading: Loading
});
const FormViewer = Loadable({
    loader: () => import('./views/Form/FormViewer'),
    loading: Loading
});
const ListViewer = Loadable({
    loader: () => import('./views/List/ListViewer'),
    loading: Loading
});
const VoucherApprove = Loadable({
    loader: () => import('./views/VoucherApprove'),
    loading: Loading
});
const Checkout = Loadable({
    loader: () => import('./views/Checkout'),
    loading: Loading
});

const CCSearchPhone = Loadable({
    loader: () => import('./views/CC/SearchPhone'),
    loading: Loading
});

const Permissions = Loadable({
    loader: () => import('./views/Permissions/Permissions'),
    loading: Loading
});
const ForgotPass = Loadable({
    loader: () => import('./views/ForgotPass/ForgotPass'),
    loading: Loading
});

const routes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
    }, {
        path: '/pageEditor',
        name: 'Quản lý trang',
        component: PageEditor
    }, {
        path: '/form',
        name: 'Trang',
        component: FormViewer
    }, {
        path: '/list',
        name: 'Trang',
        component: ListViewer
    }, {
        path: '/profile',
        name: 'Trang cá nhân',
        component: Profile
    }, {
        path: '/voucherApprove',
        name: 'Duyệt điều kiện voucher',
        component: VoucherApprove
    }, {
        path: '/checkout',
        name: 'Duyệt điều kiện voucher',
        component: Checkout
    }, {
        path: '/searchphone',
        name: 'Tìm kiếm số điện thoại',
        component: CCSearchPhone
    }, {
        path: '/permission',
        name: 'Permission',
        component: Permissions
    },
    {
        path: '/forgotPass',
        name: 'ForgotPass',
        component: ForgotPass
    }
];

export default routes;
