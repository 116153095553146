import React, { Component } from 'react';
import { Input } from 'reactstrap';
import helper from '../services/helper';
import i18next from 'i18next';

export default class SelectModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: typeof props.value === 'object' ? props.value.id : (props.value || ''),
            data: props.ARRAY || [],
            pageId: props.schema.pageId,
            schema: props.schema,
            isCallData: props.isCallData || true
        };
        if (this.state.data.length === 0) this.init(props.schema.pageId, props);
    }
    pageInfo = null;
    schema = null;
    componentWillReceiveProps(next) {
        if (this.state.isCallData) {
            if (this.props.value !== next.value) {
                this.setState({ value: next.value });
                this.fetchData(this.pageInfo, this.schema, next.value);
            }
        } else {
            this.setState({ value: next.value })
        }
    }
    async init(pageId, props) {
        this.pageInfo = await helper.getPage(pageId);
        this.schema = props.schema;
        this.fetchData(this.pageInfo, this.schema, props.value);
    }
    async fetchData(pageInfo, schema, value) {
        if (!pageInfo || !schema) {
            return;
        }
        // let filter = {};
        // filter.id = value || undefined;
        let rs = await helper.callPageApi(pageInfo, schema.api, { select: this.props.schema.modelSelectField });
        await this.setState({ data: rs.data ? rs.data : [] });
    }
    async saveData(val) {
        if (this.props.onChange) {
            this.props.onChange(val)
        }
    }
    render() {
        if (this.schema && !this.schema.modelSelectField) return <p>Missing modelSelectField</p>
        var fieldDisplay = this.props.schema.modelSelectField || 'name'
        return (
            <Input type="select" value={this.state.value} onChange={e => { this.saveData(e.target.value) }} >
                <option key={-1} value={''}>{i18next.t("choose")}</option>
                {this.state.data.map((item, idx) => <option key={idx} value={item.id}>{item[fieldDisplay]}</option>)}
            </Input>
        )
    }
}   