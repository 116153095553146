/* eslint no-eval: 0 */
import React, { Component } from 'react';
import helper from '../services/helper';
import Base from './Base';
import { Card, CardBody, CardGroup, Col, Row, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import request from '../services/request';
import Loader from '../controls/Loader';

class FormSchema extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lsLanguage: [],
            activeTab: '',
            data: props.data || {}
        }
        this.init()
    }
    async componentWillReceiveProps(next) {
        let data = next.data
        this.state.lsLanguage.map(item => {
            if (!data[item.code]) data[item.code] = {}
        })
        await this.setState({ data })
    }
    async init() {
        // let rs = await request.request('/api/language/getLanguage', {}, {}, 'POST')
        // rs.data.map(item => {
        //     this.state.data[item.code] = {}
        // })
        // await this.setState({ lsLanguage: rs.data, activeTab: rs.data[0].code })

    }
    toggleTab(code) {
        this.setState({ activeTab: code })
    }
    checkError() {
        for (let i = 0; i < this.props.schema.length; i++) {
            if (this.isInvalid(this.props.schema[i], this.props.data[this.props.schema[i].field])) {
                return i;
            }
        }
        return 0;
    }
    onSubmit() {
        console.log('on submit');
        let error = this.checkError();
        console.log('error', error);
        if (error > 0) return helper.alert(`the data incorrect. Please check again ${this.props.schema[error].name}`);
        if (this.props.onSubmit) {
            this.props.onSubmit();
        }
    }
    isInvalid(schema, value) {
        if (schema.required && (value === null || value === undefined || value === '')) return true;
        return false;
    }
    render() {
        console.log('log form ---------')
        if (!this.props.schema) return <p>not </p>
        //convert default value
        this.props.schema.map(s => {
            if (this.props.data[s.field] === undefined && s.default) this.props.data[s.field] = s.default;
            return null;
        })
        if (this.props.group && this.props.group.length !== 0) {
            this.props.group.map((gr, idx) => {
                gr.schema = []
                this.props.schema.map((item, idx) => {
                    if (gr.key === item.group) {
                        gr.schema.push(item)
                    }
                })
            })
            let group = this.props.group.filter(i => (i.schema && i.schema.length > 0))
            return (<form autoComplete='off' onSubmit={evt => {
                evt.preventDefault();
                if (this.props.onSubmit) {
                    this.props.onSubmit();
                }
            }}>
                {group.map((gr, idx) => {
                    return <Row key={idx} style={{ marginBottom: "30px" }}>
                        <Col md="12">
                            <CardGroup>
                                <Card>
                                    <CardHeader>
                                        <div className='pull-left'>
                                            <h5 className='mb-0'>
                                                {gr.name}
                                            </h5>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        {
                                            gr.schema.map((item, index) => {
                                                if (item.hideExpression) {
                                                    let str = item.hideExpression;
                                                    for (var i in this.props.data) {
                                                        str = helper.replaceAll(str, i, this.props.data[i]);
                                                    }
                                                    if (eval(str)) return null;
                                                }
                                                return <Base
                                                    key={index}
                                                    schema={item}
                                                    onChange={e => {
                                                        let dt = Object.assign({}, this.props.data);
                                                        dt[item.field] = e;
                                                        if (this.props.onChange) {
                                                            this.props.onChange(dt);
                                                        }
                                                    }}
                                                    value={this.props.data[item.field]}
                                                />
                                            })
                                        }
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                })}
                {this.props.children}
            </form>)
        } else {
            let checkMutil = false, arrMutil = [], arrUnmutil = []
            this.props.schema.map(item => {
                if (item.isMutilLanguage) {
                    checkMutil = true
                    arrMutil.push(item)
                }
                if (!item.isMutilLanguage) {
                    arrUnmutil.push(item)
                }
            })
            if (!checkMutil) {
                return (<form autoComplete='off' onSubmit={evt => {
                    evt.preventDefault();
                    if (this.props.onSubmit) {
                        this.props.onSubmit();
                    }
                }}>
                    {this.props.schema.map((comp, index) => {
                        if (comp.hideExpression) {
                            let str = comp.hideExpression;
                            for (var i in this.props.data) {
                                str = helper.replaceAll(str, i, this.props.data[i]);
                            }
                            try {
                                if (!_this)
                                    var _this = Object.assign({}, this)
                            } catch (error) {
                                var _this = Object.assign({}, this)
                            }
                            if (eval(str)) return null;
                        }
                        return <Base
                            key={index}
                            schema={comp}
                            onChange={e => {
                                let dt = Object.assign({}, this.props.data);
                                dt[comp.field] = e;
                                if (this.props.onChange) {
                                    this.props.onChange(dt);
                                }
                            }}
                            value={this.props.data[comp.field]}
                        />
                    })}
                    {this.props.children}
                </form>)
            } else {
                if (this.state.lsLanguage.length === 0) return <Loader />
                this.state.lsLanguage.map(language => {
                    language.schema = arrMutil
                    if (this.props.mode !== 'edit') {
                        language.data = {}
                    } else {
                        arrMutil.map(i => {
                            this.state.data[language.code][i.field] = this.state.data[i.field][language.code]
                        })
                        language.data = this.state.data[language.code]
                    }
                    Object.keys(language.data).map(k => !language.data[k] && delete language.data[k])
                })
                return (<form autoComplete='off' onSubmit={evt => {
                    evt.preventDefault();
                    if (this.props.onSubmit) {
                        this.props.onSubmit();
                    }
                }}>
                    {arrUnmutil.map((comp, index) => {
                        if (comp.hideExpression) {
                            let str = comp.hideExpression;
                            for (var i in this.props.data) {
                                str = helper.replaceAll(str, i, this.props.data[i]);
                            }
                            if (eval(str)) return null;
                        }
                        return <Base
                            key={index}
                            schema={comp}
                            onChange={e => {
                                let dt = Object.assign({}, this.props.data);
                                dt[comp.field] = e;
                                if (this.props.onChange) {
                                    this.props.onChange(dt);
                                }
                            }}
                            value={this.props.data[comp.field]}
                        />
                    })}
                    <Nav tabs>
                        {this.state.lsLanguage.map((i, idx) => <NavItem key={idx}>
                            <NavLink active={this.state.activeTab === i.code}
                                onClick={() => { this.toggleTab(i.code) }}>{i.name}</NavLink>
                        </NavItem>)}
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        {this.state.lsLanguage.map((lg, idx) => <TabPane key={idx} tabId={lg.code}>
                            {lg.schema.map((comp, index) => {
                                // debugger
                                if (comp.hideExpression) {
                                    let str = comp.hideExpression;
                                    for (var i in this.props.data) {
                                        str = helper.replaceAll(str, i, this.props.data[i]);
                                    }
                                    if (eval(str)) return null;
                                }
                                return <Base
                                    key={index}
                                    schema={comp}
                                    onChange={e => {
                                        let data = Object.assign({}, this.state.data)
                                        data[lg.code][comp.field] = e
                                        if (this.props.onChange) {
                                            this.props.onChange(data);
                                        }
                                    }}
                                    value={lg.data[comp.field]}
                                />
                            })}
                        </TabPane>)}
                    </TabContent>
                    {this.props.children}
                </form>)
            }
        }
    }
}

export default FormSchema;