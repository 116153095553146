import Text from './Text';
import SingleModel from './SingleModel';
import Checkbox from './Checkbox';
import Date from './Date';
import Time from './Time';
import Location from './Location';
import Image from './Image';
import TextArea from './TextArea';
import RichText from './RichText';
import Enum from './Enum';
import ArrayImage from './ArrayImage';
import ArrayModel from './ArrayModel';
import Password from './Password';
import Upload from './Upload';
import Captcha from './Captcha';
import TabsLanguage from './TabsLanguage';
import SelectModel from './SelectModel';
import TableSchema from './TableSchema';
import TableGenerate from './TableGenerate';
import SelectMutilple from './SelectMutilple'
import CkEditorSchema from './CkEditorSchema'
export default {
    Time,
    Captcha,
    Upload,
    Password,
    ArrayModel,
    ArrayImage,
    Enum,
    RichText,
    TextArea,
    Image,
    Location,
    Text,
    SingleModel,
    Checkbox,
    Date,
    TabsLanguage,
    SelectModel,
    TableSchema,
    TableGenerate,
    SelectMutilple,
    CkEditorSchema
};
