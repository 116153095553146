import React, {Component} from 'react';
import {Input} from 'reactstrap';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import request from './../services/request';
import 'react-tabs/style/react-tabs.css';
class TabsLanguage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: {}

        }

    }
    async init() {
        this.setState({languages: null});
        let languages = await request.request('/api/language/getLanguage');
        this.setState({languages})
        await this.setState({mode: this.query.mode});
        this.fetchData();
    }


    render() {
        let type = '';
        switch (this.props.schema.type) {
            case 'number': type = 'number';
                break;
            default: type = 'text';
        }

        let languages = {
            data: [
                {
                    code: 'vi',
                    name: "Vietnamese"
                }, {
                    code: 'en',
                    name: "English"
                },
            ]
        }
        if (this.props.schema.hideValue) 
            type = 'password';
        


        if (this.props.schema.type === 'json' && this.props.schema.widget === 'TabsLanguage') {}

        return <Tabs>
            <TabList> {
                languages.data.map((item, index) => {
                    return <Tab> {
                        item.name
                    }</Tab>
            })
            } </TabList>

            {
            languages.data.map((item, index) => {
                return <TabPanel>
                    <Input type={type}
                        invalid={
                            this.props.invalid || false
                        }
                        placeholder={
                            this.props.schema.placeholder
                        }
                        id={
                            item.code
                        }
                        value={
                            this.props.value
                        }
                        name={
                            item.code
                        }
                        key={
                            item.code
                        }
                        onChange={
                            evt => {
                                if (this.props.onChange) {
                                    this.props.onChange(evt.target.value);
                                }
                            }
                        }/>
                </TabPanel>
        })
        } </Tabs>

    }
}
export default TabsLanguage;
