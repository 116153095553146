import React from 'react'
import { Table, Button, FormGroup, Label, Col } from 'reactstrap';
import _ from 'lodash';
import Widgets from './Widgets';
import Loader from '../controls/Loader';

const lsData = [
    { id: 'string', name: 'String' },
    { id: 'number', name: 'Number' },
    { id: 'boolean', name: 'Boolean' },
    { id: 'float', name: 'Float' },
    { id: 'float', name: 'Float' },
    { id: 'array', name: 'Array' },
]

export default class TableGenerate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.value || [],
            schema: props.schema || {}
        }
        this.init(props)
    }
    async init(props) {
        let schema = props.schema
        schema.items.map(i => {
            i.schema = {}
            i.schema.placeholder = i.placeholder
            i.schema.type = i.dataType
            i.value = ''
        })
        await this.setState({ schema })
    }
    addItem() {
        let data = _.clone(this.state.data);
        let arrKey = [], obj = {}
        if (this.state.schema && this.state.schema.items) {
            arrKey = this.state.schema.items.map(i => i.field)
        }
        arrKey.map(i => obj[i] = '')
        data.push(obj)
        this.setState({ data });
    }
    removeItem(index) {
        let data = _.clone(this.state.data);
        data.splice(index, 1);
        this.setState({ data });
        if (this.props.onChange) {
            this.props.onChange(data);
        }
    }
    onItemDataChange(index, name, val) {
        let data = _.clone(this.state.data);
        let item = data[index];
        item[name] = val;
        data[index] = item;
        this.setState({ data });
        if (this.props.onChange) {
            this.props.onChange(data);
        }
    }

    render() {
        if (!this.state.schema) return <Loader />
        return <FormGroup row>
            <Col className='form-label-horizontal' md='3'>
                <Label>
                    <b> {
                        this.props.schema.name
                    }
                        {
                            this.props.schema.required ? '(*)' : ''
                        }</b>
                </Label>
            </Col>
            <Col md='8'>
                <Table bordered>
                    <thead>
                        <tr>
                            {
                                this.props.schema.items.map((i, idx) => <th key={idx}>{i.title}</th>)
                            }
                            <th style={{ width: '50px' }}><Button className="btn btn-default btn-sm" onClick={this.addItem.bind(this)}><i className="fa fa-plus"></i></Button></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.data.map((dt, index) => {
                                return <tr key={index}>
                                    {
                                        this.state.schema.items.map((i, idx) => {
                                            let Widget = Widgets[i.interfaceType];
                                            if (!Widget) return <p>Invalid type {i.interfaceType}</p>
                                            return <td key={idx}>
                                                <Widget onChange={e => { this.onItemDataChange(index, i.field, e) }}
                                                    value={dt[i.field] || ''}
                                                    schema={i.schema}
                                                    ARRAY={lsData}
                                                    isCallData={false} />
                                            </td>
                                        })
                                    }
                                    <td><Button className="btn btn-danger btn-sm" onClick={e => { this.removeItem(index) }}><i className="fa fa-trash"></i></Button></td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>
            </Col>
        </FormGroup>
    }
}