import React, { Component } from 'react';
import DatePickerComponent from "react-datepicker";
import moment from 'moment';

// import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: props.schema,
            value: props.value ? moment(props.value).toDate() : new Date(),
            focus: false
        };
    }
    onChange(val) {
        if (val) {
            // this.setState({ value: val });
            this.props.onChange(new Date(val));
        } else {
            this.props.onChange(null)
        }
    }
    componentWillReceiveProps(next) {
        if (next.value !== moment(this.state.value).valueOf()) {
            this.setState({ value: next.value ? moment(next.value).toDate() : new Date() });
        }
    }
    render() {
        return (<div>
            {/* <DatePickerComponent
                selected={this.props.value ? moment(this.props.value).toDate() : null}
                onChange={e => {
                    this.onChange(e);
                }}
                showTimeSelect
                timeFormat="HH:mm"
                className="form-control full-width"
                dateFormat='dd/MM/yyyy HH:mm'
                disabled={this.state.schema.disabled}
            /> */}
            {/*<DayPickerInput
                format="DD/MM/YYYY"
                value={this.props.value && moment(this.props.value).format('DD/MM/YYYY')}
                onDayChange={e => this.onChange(e)}
                placeholder={'DD/MM/YYYY'}
            />*/}

            <DatePickerComponent
                className="form-control"
                selected={ this.props.value }
                onChange={e => {
                    this.onChange(e);
                }}
                className="form-control full-width"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="yyyy-MM-dd HH:mm"
            />
        </div>)
    }
}

export default DatePicker;