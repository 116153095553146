import React, { Component } from 'react';
import TinyMCE from 'react-tinymce';
class RichText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: !props.value
        };
    }
    handleEditorChange(e) {
        let val = e.target.getContent();
        this.setState({ value: val });
        if (this.props.onChange) {
            this.props.onChange(val);
        }

    }
    componentWillReceiveProps(next) {
        this.setState({ value: next.value });
    }

    render() {
        return (<div>
            <TinyMCE
                content={this.props.value}
                config={{
                    readonly: this.props.disabled,
                    plugins: 'autolink link image lists print preview',
                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright'
                }}
                onChange={this.handleEditorChange.bind(this)}
            />
        </div>)
    }
}

export default RichText;