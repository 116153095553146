import React, { Component } from 'react';
import { Button, ModalFooter } from 'reactstrap';
import helper from '../services/helper';
import FormSchema from '../schema';
import Local from '../services/local';
import Loader from './Loader';
import i18next from 'i18next';
import Swal from 'sweetalert2';
import api from '../services/api';
import request from '../services/request';


export default class FormCtrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.query.embed ? JSON.parse(this.props.query.embed) : null,
            pageInfo: null,
            error: null,
            loading: true,
            lsLanguage: []
        }
    }
    componentDidMount() {
        this.loadData();
    }
    componentWillReceiveProps(next) {
        // debugger
        this.loadData(next);
    }
    async loadData(props) {
        if (!props)
            props = this.props;


        // let rs = await api.getLanguage()
        // this.setState({  })
        let pageInfo = await helper.getPage(props.query.page);
        console.log('PAGE INFO', pageInfo);
        if (!pageInfo) {
            Swal.fire({ title: 'Error!', text: i18next.t('notFoundPage'), icon: 'error', confirmButtonText: 'OK' })
        } else {
            let mutilLanguage = false
            pageInfo.schema.map(i => {
                if (i.isMutilLanguage) {
                    mutilLanguage = true
                }
            })

            await this.setState({ pageInfo, mutilLanguage, mode: props.query.mode });

            if (props.query.mode === 'edit'||props.query.mode === 'changed') {
                // if (!this.state.data) {
                if (!props.query.id) {
                    return this.setState({ error: i18next.t('noData') })
                }
                let data = {}
                let obj = pageInfo.buttons.find(i => i.apiBasic && i.mode == 'edited')
                if (obj) {
                    let api = helper.getApiByName(pageInfo.apis, obj.api);                
                    let rs = await request.request(api.url, { id: props.query.id }, {}, 'POST')

                    data = rs && rs.data || {}
                } else {

                    let rs = await helper.callPageApi(pageInfo, pageInfo.read, {
                        queryInput: JSON.stringify(
                            { id: props.query.id }
                        )
                    });
                    data = rs && rs.data[0] || {};
                    if (data.status && data.status == 'active') {
                        data.status = true
                    } else {
                        data.status = false
                    }
                }
                if (props.query.embed) {
                    Object.assign(data, JSON.parse(props.query.embed));
                }
                this.setState({ data })
                // }
            }
        }
    }
    async onSubmit() {
        this.onButtonClick();
    }

    async onButtonClick(btnInfo) {
        if (!btnInfo) {
            for (var i = 0; i < this.state.pageInfo.buttons.length; i++) {
                if (this.state.pageInfo.buttons[i].mode === this.props.query.mode) {
                    btnInfo = this.state.pageInfo.buttons[i];
                    break;
                }
            }
        }
        if (btnInfo) {
            try {
                if (btnInfo.type === 'submit' && btnInfo.action === 'api') {
                    if (this.formRef) {
                        let error = this.formRef.checkError();
                        if (error > 0)
                            return helper.alert(`${
                                i18next.t('data_incorrect_please_check_again')
                                } ${
                                this.state.pageInfo.schema[error].name
                                }`);



                    }
                }

                let data = Object.assign({}, this.state.data);
                if (this.state.mode !== 'edit' && this.state.mutilLanguage) {
                    let arrSchemaMutil = [], codeLg = []
                    this.state.pageInfo.schema.map(i => { i.isMutilLanguage && arrSchemaMutil.push(i) })
                    this.state.lsLanguage.map(lg => codeLg.push(lg.code))
                    arrSchemaMutil.map(item => {
                        data[item.field] = {}
                        codeLg.map(code => {
                            if (data[code][item.field])
                                data[item.field][code] = data[code][item.field]
                        })
                    })
                    codeLg.map(code => delete data[code])
                }
                if (btnInfo.confirm) {
                    let confirmText = btnInfo.confirm;
                    for (var f in data) {
                        confirmText = helper.replaceAll(confirmText, '#' + f + '#', data[f]);
                    }
                    let rs = await helper.confirm(confirmText);
                    console.log('confirm', rs);
                }
                if (this.props.query.embed && btnInfo.embedUrl) {
                    data = Object({}, data, JSON.stringify(this.props.query.embed));
                }
                let response = {}
                if (btnInfo.apiBasic) {
                    debugger
                    let api = helper.getApiByName(this.state.pageInfo.apis, btnInfo.api);
                    response = await request.request(api.url, data, {}, 'POST')
                } else {
                    response = await helper.callPageApi(this.state.pageInfo, btnInfo.api, data);
                }
                console.log('data')
                if (response && response.err && response.err == 200) {
                    if (btnInfo.backOnDone) {
                        window.history.back();
                    } else if (this.props.showModal) {
                        if (this.props.onChange) {
                            this.props.onChange({ isShowModal: false, message: response.message || i18next.t('successfully') })
                        }
                    }
                } else {
                    helper.alert(response.message || i18next.t('invalidParam'));
                }
            } catch (err) {
                helper.alert(err.message);
            }
        } else {
            helper.alert(i18next.t('no_have_button'));
        }
    }
    async  setData(data) {
        let arrSchemaMutil = [], codeLg = []
        this.state.pageInfo.schema.map(i => { i.isMutilLanguage && arrSchemaMutil.push(i) })
        if (arrSchemaMutil.length > 0) {
            this.state.lsLanguage.map(lg => codeLg.push(lg.code))
            arrSchemaMutil.map(item => {
                data[item.field] = {}
                codeLg.map(code => {
                    if (data[code][item.field])
                        data[item.field][code] = data[code][item.field]
                })
            })
            codeLg.map(code => delete data[code])
        }
        await this.setState({ data })
    }
    render() {
        if (this.state.mode === 'edit' && !this.state.data)
            return <Loader />;



        if (this.state.error)
            return (<p className='text-danger'> {
                this.state.error
            }</p>)





        if (!this.state.pageInfo)
            return null;



        return (<FormSchema ref={
            ref => {
                this.formRef = ref;
            }
        }
            mode={
                this.state.mode
            }
            schema={
                this.state.pageInfo.schema
            }
            group={
                this.state.pageInfo.group
            }
            data={
                this.state.data || {}
            }
            onChange={
                data => {
                    // this.setState({ data })
                    // this.setData(data)
                    if (this.state.mode === 'edit' && this.state.mutilLanguage) {
                        this.setData(data)
                    } else {
                        this.setState({ data })
                    }
                }
            }
            onSubmit={
                this.onSubmit.bind(this)
            }><br /> <ModalFooter>{
                this.state.pageInfo.buttons.map((item, index) => {
                    if (this.state.mode === item.mode) {
                        let url = '';
                        var i = 0;
                        switch (item.action) {
                            case 'url': url = item.url.replace('$', this.state.data);
                                for (i in this.state.data) {
                                    url = helper.replaceAll(url, '#' + i + '#', this.state.data[i]);
                                }
                                for (i in this.query) {
                                    url = helper.replaceAll(url, '@' + i + '@', this.props.query[i]);
                                }
                                return <a key={index}
                                    href={url}
                                    className={
                                        `btn btn-${
                                        item.color
                                        } mr-1`
                                    }><i className={
                                        item.icon
                                    } /> {
                                        item.title
                                    }</a>
                            case 'api':
                            case 'formModal':
                                return <Button key={index}
                                    className='mr-1'
                                    color={
                                        item.color
                                    }
                                    onClick={
                                        () => {
                                            this.onButtonClick(item)
                                        }
                                    }><i className={
                                        item.icon
                                    } /> {
                                        item.title
                                    }</Button>
                            case 'report': url = item.url.replace('$', this.state.data);
                                for (i in this.state.data) {
                                    url = helper.replaceAll(url, '#' + i + '#', this.state.data[i]);
                                }
                                for (i in this.query) {
                                    url = helper.replaceAll(url, '@' + i + '@', this.props.query[i]);
                                }
                                url += '&accesstoken=' + Local.get('session');
                                return <a key={index}
                                    href={url}
                                    className={
                                        `btn btn-${
                                        item.color
                                        } mr-1`
                                    }><i className={
                                        item.icon
                                    } /> {
                                        item.title
                                    }</a>
                            default:
                                return null;
                        }
                    }
                    return null;
                })
            } </ModalFooter></FormSchema>);
    }
}
