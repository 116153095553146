import React from 'react';
import CKEditor from "react-ckeditor-component";

export default class CkEditorSchema extends React.Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this);
    }
    onChange(evt) {
        if (this.props.onChange) {
            this.props.onChange(evt.editor.getData())
        }
    }
    render() {
        return (
            <CKEditor
                activeClass="p10"
                content={this.props.value}
                events={{
                    "change": this.onChange
                }}
            />
        )
    }

}