let config = {
   
    host: 'https://partnerapi.ayapay.com',
    // host: 'http://localhost:1338',  
    defaultAvatar: "https://www.kindpng.com/picc/m/130-1300217_user-icon-member-icon-png-transparent-png.png",
    Urls3: "https://dev-ayaloyalty-storage.s3-ap-southeast-1.amazonaws.com/",
    debug: true,
    REGEX_USER: '[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9]){5,40}$',
    REGEX_PASSWORD: '[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9]){8,40}$',
    DEFAULT_TOKEN: { 'Authorization': `Bearer xxnBpuxNrRKs6H6Wq3J7KzYDkPFAyzW9` }


}
export default config;


