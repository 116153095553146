import React, { Component } from 'react';
import { Table, Input, Button } from 'reactstrap';
import DatePickerComponent from "react-datepicker";
import moment from 'moment';
import _ from 'lodash';

export default class TableSchema extends Component {
    constructor(props) {
        super(props)
        this.state = { data: props.value || [] }
        // if (props.value && props.value.rule && props.value.rule.length > 0) { 
        //     this.setState({ data: props.value.rule }) 
        // }
        // else { this.setState({ data: props.value || [] }) }
    }
    addItem() {
        let data = _.clone(this.state.data);
        data.push({ order: '', date: '' });
        this.setState({ data });
    }
    removeItem(index) {
        let data = _.clone(this.state.data);
        data.splice(index, 1);
        this.setState({ data });
        if (this.props.onChange) {
            this.props.onChange(data);
        }
    }
    onItemDataChange(index, name, val) {
        let data = _.clone(this.state.data);
        let item = data[index];
        item[name] = val;
        data[index] = item;
        this.setState({ data });
        if (this.props.onChange) {
            this.props.onChange(data);
        }
    }

    render() {
        return (<Table bordered>
            <thead>
                <tr>
                    <th style={{ width: '450px' }}>Order</th>
                    <th style={{ width: '450px' }}>Date</th>
                    <th><Button className="btn btn-default btn-sm" onClick={this.addItem.bind(this)}><i className="fa fa-plus"></i></Button></th>
                </tr>
            </thead>
            <tbody>

                {this.state.data.map((item, idx) => {
                    return <tr>
                        <td><Input type="number" value={item.order || ''} onChange={e => { this.onItemDataChange(idx, 'order', e.target.value) }} /></td>
                        <td><DatePickerComponent
                            selected={item.date ? moment(item.date).toDate() : null}
                            onChange={e => {
                                this.onItemDataChange(idx, 'date', e.toISOString());
                            }}
                            showTimeSelect
                            timeFormat="HH:mm"
                            className="form-control full-width"
                            dateFormat='dd/MM/yyyy HH:mm'
                        /></td>
                        <td><Button className="btn btn-danger btn-sm" onClick={e => { this.removeItem(idx) }}><i className="fa fa-trash"></i></Button></td>
                    </tr>
                })}

            </tbody>
        </Table>)
    }
}