import request from './request';

var config = {}



var path = {
    //login
    login: '/api/user/login',
    resetPass: '/api/user/resetPass',
    createNewPass: '/api/user/createNewPass',
    resetPassVerifyOTP: '/api/user/resetPassVerifyOTP',

    logout: '/api/user/logout',
    getCaptcha: '/api/user/create-captcha',
    getMeta: '/api/admin/get-meta',
    getLanguage: '/api/language/getLanguage',
    // TuPT ADD 04-03-2020
    getListService: '/api/service/lsService',
    findOnePointReward: '/pointRewardRule/findOne',
    createPointReward: '/pointRewardRule/create',
    findOneCondition: '/api/condition/findOneCondition',
    createCondition: '/api/condition/create',
    updateCondition: '/api/condition/update',
    findOneCompany: '/company/findOneCompany',
    updateStatusService: '/service/changeStatus',
    deleteService: '/service/deleteService',
    getListTier: '/api/tier/listTier',
    findOnePromotion: '/promotion/findOnePromotion',
    createPromotion: '/promotion/createPromotion',
    updatePromotion: '/promotion/updatePromotion',

    changePassRP_User: '/api/user/changePassRP_User',
    updatePassProfile: '/api/user/updatePassProfile',




    reportTransactionSompo: '/api/report/reportTransactionSompo',
    exportTransactionSompo: '/api/report/exportTransactionSompo',
    findConfig: '/api/config/findConfig'

};


Object.keys(path).forEach(function (key) {

    config[key] = async function (data, token) {
        let result = await request.request(path[key], data, token)
        return result
    }
}, this);




export default config;