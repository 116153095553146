import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import App from './App';
import configureStore from './store';
import ModalControl from './controls/ModalControl';
// disable ServiceWorker
// import registerServiceWorker from './registerServiceWorker';
import './i18n/i18n';
// import i18next from 'i18next';
// import en from './i18n/en.json'
// import vi from './i18n/vi.json'


// i18next.init({
//     lng: 'en',
//     debug: true,
//     resources: {
//         en: {
//             translation: en
//         },
//         vi: {
//             translation: vi
//         }
//     }
// }, function (err, t) {
//     // initialized and ready to go!
//     document.getElementById('root').innerHTML = i18next.t('key');
// });
let store = configureStore();
ReactDOM.render(<Provider store={store}>
    <React.Fragment>
        <ModalControl />
        <App />
    </React.Fragment>
</Provider>, document.getElementById('root'));
// disable ServiceWorker
// registerServiceWorker();

