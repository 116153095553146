import React from 'react';
import PropTypes from 'prop-types';
import AvatarEditor from 'react-avatar-editor';

const ImageCrop = ({ imageSrc, onCrop, setEditorRef, scaleValue, onScaleChange, width, height }) => (
    <div>
        <div className="editorOverlayInner">
            <div className="editorModalContent clearfix">
                <div className="cropCnt">
                    <AvatarEditor image={imageSrc} border={50} scale={scaleValue} rotate={0} ref={setEditorRef} className="cropCanvas" width={width} height={height} />
                    <input style={{ width: '60%' }} type="range" value={scaleValue} name="points" min="1" max="10" onChange={onScaleChange} /><br/>
                    <button onClick={onCrop} className="btn btn-success btn-sm">
                        Crop
            </button>
                </div>
            </div>
        </div>
    </div>
);

ImageCrop.propTypes = {
    setEditorRef: PropTypes.func.isRequired,
    onCrop: PropTypes.func.isRequired,
    scaleValue: PropTypes.number.isRequired,
    onScaleChange: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
};

export default ImageCrop;
