import React, { Component } from 'react';
import Widgets from './Widgets';
import { FormGroup, Label, Col } from 'reactstrap'
import TableGenerate from './TableGenerate'


class Base extends Component {
    error() {
        if (this.isInvalid()) {
            return <p className='text-danger'>
                <b> {
                    this.props.schema.name
                }
                    &nbsp;is required!</b>
            </p>
        }
    }


    isInvalid() {
        if (this.props.schema.required && (this.props.value === null || this.props.value === undefined || this.props.value === ''))
            return true;



    }

    render() {
        if (this.props.schema.widget === 'TableGenerate') {
            return <TableGenerate schema={this.props.schema} value={this.props.value} onChange={this.props.onChange} />
        } else {
            let Widget = Widgets[this.props.schema.widget];
            console.log('Widget', Widget)
            if (!Widget) {
                return <p>Invalid type {
                    this.props.schema.widget
                }</p>
            }
            return <FormGroup row>
                <Col className='form-label-horizontal' md='3'>
                    <Label>
                        <b> {
                            this.props.schema.name
                        }
                            {
                                this.props.schema.required ? '(*)' : ''
                            }</b>
                    </Label>
                </Col>
                <Col md='8'>
                    <Widget onChange={
                        this.props.onChange
                    }
                        value={
                            this.props.value === undefined ? this.props.schema.default : this.props.value
                        }
                        ARRAY={
                            this.props.schema.items || []
                        }
                        schema={
                            this.props.schema
                        }
                        invalid={
                            this.isInvalid()
                        } /> {
                        this.error()
                    }
                </Col>
            </FormGroup>
        }
    }
}

export default Base;
