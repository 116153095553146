import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import request from '../../services/request.js';
import api from '../../services/api.js';
import local from '../../services/local.js';
import helper from '../../services/helper';
import Loader from '../../controls/Loader';
// import AccountKit from 'react-facebook-account-kit';
import { Trans } from 'react-i18next';
import i18next from 'i18next';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      loading: true,
      captchaId: 0,
      captchaText: '',
      captcha: null,
      accountKitToken: ''
    }
  }
  componentDidMount() {
    this.loadCaptcha();
  }
  async loadCaptcha() {
    //load captcha
    let captInfo = await api.getCaptcha();
    this.setState({ loading: false, captchaId: captInfo.id, captcha: captInfo.data })
  }
  async onLoginClick(e) {
    e.preventDefault();
    try {
      if (!this.state.username)
        return helper.alert(i18next.t('Plese enter username'));
      if (!this.state.password)
        return helper.alert(i18next.t('Plese enter password'));
      if (!this.state.captchaText)
        return helper.alert(i18next.t('Plese enter capcha'));
      let rs = await api.login({ username: this.state.username, password: this.state.password, captchaId: this.state.captchaId, captchaText: this.state.captchaText, accountKitToken: this.state.accountKitToken });
      if (!rs || (rs && rs.code != 0))
        return helper.alert(i18next.t(rs.message));
      local.set('session', rs.data.token);
      local.set('user', JSON.stringify(rs.data.userInfo));
      this.props.history.push(`/dashboard`);

    } catch (err) {
      console.log("loadCaptcha", err)
      this.loadCaptcha();
      helper.alert(err.message);
    }
    // return false;
  };

  async resetPass(e) {
    return this.props.history.push(`/forgotPass`);
  }


  async handleAccountKitResponse(result) {
    console.log('on token', result);
    this.setState({ accountKitToken: result.code });
  }
  render() {
    if (this.state.loading) return <Loader />
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Row>

                      <Col md={12}>
                        <img src='assets/img/ayalogo.png' className='login-logo' alt='avatar' />
                      </Col>
                    </Row>


                    <form onSubmit={this.onLoginClick.bind(this)} autoComplete='off'>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" placeholder={i18next.t('username')} value={this.state.username} onChange={evt => this.setState({ username: evt.target.value })} />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" placeholder={i18next.t('password')} value={this.state.password} autoComplete='off' onChange={evt => this.setState({ password: evt.target.value })} />
                      </InputGroup>
                      {/* <InputGroup className="mb-4">
                        <AccountKit
                          appId="807230106321433" // Update this!
                          version="v1.0" // Version must be in form v{major}.{minor}
                          onResponse={(resp) => this.handleAccountKitResponse(resp)}
                          csrf={'csrf token here!'} // Required for security
                          countryCode={'+84'} // eg. +60
                          phoneNumber={''} // eg. 12345678
                          emailAddress={''} // eg. me@site.com
                        >
                          {p => {
                            return this.state.accountKitToken ?
                              <Button variant="contained" {...p} size="medium" color="success" block>
                                Đã xác thực. Thay đổi?
                          </Button>
                              : <Button variant="contained" outline {...p} size="medium" color="primary" block>
                                Xác thực số điện thoại
                            </Button>
                          }
                          }

                        </AccountKit>
                      </InputGroup> */}


                      <Row className='mb-4'>
                        <Col md={6}>
                          <div className='captcha' dangerouslySetInnerHTML={{ __html: this.state.captcha }}></div>
                        </Col>
                        <Col md={6}>
                          <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                              <Input type="text" placeholder={i18next.t('Please Enter Captcha')} value={this.state.captchaText} onChange={evt => this.setState({ captchaText: evt.target.value })} />
                              <InputGroupAddon addonType="prepend">
                                <Button color='light' type='button' onClick={() => {
                                  this.loadCaptcha();
                                }}><i className='fa fa-refresh' /></Button>
                              </InputGroupAddon>
                            </InputGroupAddon>

                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <Button color="primary" type='submit' className="color-logo-ayapay" block>{i18next.t('login')}</Button>
                        </Col>
                        {/* <Col xs="6" className="text-right">
                          <Button color="link" className="px-0" type='button' block onClick={() => { this.resetPass() }} >{i18next.t('Forgot password?')}</Button>
                        </Col> */}
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
